export default class UsuarioPerfilSerializer {
  fromJson(json) {
    const state = {
      id: json.id_usuario_perfil,
      perfil: json.perfil,
      value: {
        id: json.id_usuario_perfil,
        profile: json.perfil,
      },
      label: json.perfil,
      imagem: json.imagem_logo,
      certificado: json.certificado,
    };

    return state;
  }
}
