import React from "react";
import "./headerCursos.scss";
import InfoCard from "../InfoCard/InfoCard";
import arrow from "./icones/arrow-right.svg";
import iconUpdate from "./icones/update.svg";
import iconSchedule from "./icones/schedule.svg";
import CustomProgressBar from "../ProgressBar/ProgressBar";
import Breadcrumb from "components/Breadcamb";
import { useMediaQuery } from "react-responsive";

const HeaderCursos = ({ curso, user }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" }); // Define se é um dispositivo móvel

  return (
    <div>
      <div className="container-header-cursos">
        <div className="container-colunas">
          <div className="coluna-esquerda">
            {isMobile ? (
              <Breadcrumb
                tipo="mobile"
                title={curso.titulo}
                arrow={arrow}
                cor={"branco"}
              />
            ) : (
              <Breadcrumb
                tipo="desk"
                title={curso.titulo}
                arrow={arrow}
                cor={"branco"}
              />
            )}
            <div className="container-title-card">
              <div className="info-title">
                <h3>{curso.titulo}</h3>
              </div>
              <div className="container-cards">
                <div className="card-info">
                  <InfoCard
                    icon={iconSchedule}
                    title={"Carga horária"}
                    infoHora={curso.cargaHoraria}
                  />
                  <InfoCard
                    icon={iconUpdate}
                    title={"Ultima Atualização"}
                    info={curso.dataUltimaAtualizacao}
                  />
                </div>
                {/*   <InfoCard
                  icon={iconPerson}
                  title={"Alunos"}
                  info={` ${cursoEncontrado.alunos_matriculados} alunos`}
                /> */}
                {/*  */}
                <div className="card-progress"><CustomProgressBar curso={curso} user={user} /></div>
              </div>
            </div>
          </div>
          {/* <div className="coluna-direita">
            <CustomProgressBar curso={curso} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderCursos;
